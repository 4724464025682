<template>
  <div>
    <b-modal size="xl" title="MFA 인증 안내" v-model="modal" ok-only ok-title="닫기">
      <div class="font-lg bold mb-3">
        4월 21일부터 사무실 외의 환경에서 로그인 시 MFA 인증이 추가됩니다.<br/>
        4월 25일부터 기존의(4월 19일 이전에 사용하던) 비밀번호를 변경하지 않을 경우 로그인이 불가합니다.<br/>
        원활한 이용을 위해 미리 Email, 휴대폰 인증, 비밀번호 변경을 진행해주세요.
      </div>

      <h5 class="bold mt-5">필수항목</h5>
      <b-alert variant="danger" :show="!$S.user.email_checked_dt && !$S.user.mobile_checked_dt">Email 이나 휴대폰 인증이 완료되지 않은 경우 로그인이 불가할 수 있습니다</b-alert>
      <h6 class="bold mt-4">Email 인증 <b-badge v-if="$S.user.email_checked_dt" variant="success">완료됨</b-badge></h6>
      <b-input-group class="w-300px">
        <b-input class="" v-model="item.email" :readonly="readonly.email" autocomplete="off"></b-input>
        <b-input-group-append v-if="item.email === item.email_auth && item.email_txid">
          <b-button variant="secondary" disabled>
            인증됨
          </b-button>
        </b-input-group-append>
        <template v-else>
          <b-input-group-append v-if="item.email !== item_org.email || !item_org.email_checked_dt">
            <b-button class="" variant="success" @click="sendAuthEmail" :disabled="busy.sendEmail || busy.waitEmail">
              <span v-if="item.email_sended && item.email === item.email_sended">
                재발송
              </span>
              <span v-else>
                인증메일발송
              </span>
              <b-spinner class="mr-1" small v-if="busy.sendEmail"></b-spinner>
            </b-button>
          </b-input-group-append>
        </template>
      </b-input-group>
      <template v-if="item.email_sended && item.email === item.email_sended && !item.email_txid">
        <b-input-group class="w-300px">
          <b-input v-model="item.emailAuthCode" :placeholder="emailLimitSecond ?
           `${Math.floor(emailLimitSecond / 60)}:${(emailLimitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
            `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
          <b-input-group-append>
            <b-button class="" variant="primary" @click="checkAuthEmail" :disabled="busy.checkEmail || !emailLimitSecond">
              인증
              <b-spinner class="mr-1" small v-if="busy.checkEmail"></b-spinner>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>

      <h6 class="bold mt-4">휴대폰 번호 인증(숫자로만 입력해주세요) <b-badge v-if="$S.user.mobile_checked_dt" variant="success">완료됨</b-badge></h6>
      <b-input-group class="w-300px">
        <b-input v-model="item.mobile" :readonly="readonly.mobile" autocomplete="off"></b-input>
        <b-input-group-append v-if="item.mobile === item.mobile_auth && item.mobile_txid">
          <b-button variant="secondary" disabled>
            인증됨
          </b-button>
        </b-input-group-append>
        <template v-else>
          <b-input-group-append v-if="item.mobile !== item_org.mobile || !item_org.mobile_checked_dt">
            <b-button class="" variant="success" @click="sendAuthSMS" :disabled="busy.sendSMS || busy.waitSMS">
              <span v-if="item.mobile_sended && item.mobile === item.mobile_sended">
                재발송
              </span>
              <span v-else>
                인증문자발송
              </span>
              <b-spinner class="mr-1" small v-if="busy.sendSMS"></b-spinner>
            </b-button>
          </b-input-group-append>
        </template>
      </b-input-group>
      <template v-if="item.mobile_sended && item.mobile === item.mobile_sended && !item.mobile_txid">
        <b-input-group class="w-300px">
          <b-input v-model="item.smsAuthCode" :placeholder="smsLimitSecond ?
           `${Math.floor(smsLimitSecond / 60)}:${(smsLimitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
            `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
          <b-input-group-append>
            <b-button class="" variant="primary" @click="checkAuthSMS" :disabled="busy.checkSMS || !smsLimitSecond">
              인증
              <b-spinner class="mr-1" small v-if="busy.checkSMS"></b-spinner>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>

      <h6 class="bold mt-4">
        비밀번호 변경
        <a href="https://skyksit.tistory.com/entry/%EB%B9%84%EB%B0%80%EB%B2%88%ED%98%B8-%EC%B2%B4%EA%B3%84-%EB%B3%80%EA%B2%BD-%EB%90%A8-11%EB%85%84-%EB%A7%8C%EC%97%90" target="_blank">(참고링크)</a>
        <b-badge v-if="$S.user.pw_mod_dt" variant="success">완료됨</b-badge>
      </h6>
      <b-alert class="mt-3" variant="danger" :show="!$S.user.pw_mod_dt">비밀번호 변경이 완료되지 않은 경우 비밀번호를 초기화해야 로그인이 가능해집니다.</b-alert>

      <div style="height: 0px; overflow: hidden">
        <!-- 휴대폰번호에 id 가 들어가는 auto complete 방지 -->
        <b-input type="text"></b-input>
      </div>
      <div class="grid">
        <div class="td col-form-label">기존 비밀번호</div>
        <div class="td">
          <b-input type="password" class="w-200px" v-model="form.pw"></b-input>
        </div>
        <div class="td col-form-label">새 비밀번호</div>
        <div class="td">
          <b-input type="password" class="w-200px" v-model="form.new_pw"></b-input>
        </div>
        <div class="td col-form-label">새 비밀번호 확인</div>
        <div class="td">
          <b-input type="password" class="w-200px" v-model="form.new_pw_again"></b-input>
        </div>
      </div>

      <b-button variant="success" @click="changePW()" :disabled="busy.pw">
        비밀번호 변경
        <b-spinner class="mr-1" small v-if="busy.pw"></b-spinner>
      </b-button>
      <hr/>

      <h5 class="bold mt-5">선택항목</h5>
      <h6 class="bold mt-4">Google OTP 등록 <b-badge v-if="$S.user.otp_reg_dt" variant="success">완료됨</b-badge></h6>
      <div class="mb-2">
        Google OTP는 고정된 비밀번호가 아닌 무작위로 생성되는 일회용 비밀번호를 이용하는 본인 인증 수단입니다.<br/>
        Google OTP를 활성화해서 사용하신다면 문자 / Email 로 코드를 받는것보다 간편한 사용이 가능합니다.<br/>
        먼저 Google OTP 앱을 스토어에서 다운받아 설치 후 진행해주세요.<br/>
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Play Store (안드로이드)</a> <br/>
        <a href="https://apps.apple.com/app/google-authenticator/id388497605" target="_blank">App Store (iOS)</a> <br/>
        <br/>
        <a href="https://support.playtera.co.kr/hc/ko/articles/360003691315-OTP-%EC%9D%B8%EC%A6%9D-%EC%95%B1-%EB%93%B1%EB%A1%9D-%EB%B0%A9%EB%B2%95" target="_blank">(참고링크)</a>
      </div>
      <template v-if="!$S.user.otp_reg_dt">
        <img v-if="form.qr" :src="form.qr" style="border: 1px solid #000" alt="QR 이미지"/><br/>
        <!--      <b-input-group class="mt-2 w-300px">
                <b-form-input maxlength="6" class="form-control" v-model="form.otpSecret" placeholder="설정 키"/>
                <b-input-group-append><b-button variant="primary" @click="$utils.copyAlert(form.otpSecret)">설정 키 복사</b-button></b-input-group-append>
              </b-input-group>-->
        <b-input-group class="mt-2 w-300px">
          <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
          <b-form-input maxlength="6" class="form-control" v-model="form.otpCode" placeholder="OTP 코드 입력(6자리)" @keypress.enter="registOtp"/>
          <b-input-group-append><b-button variant="success" @click="registOtp">등록하기</b-button></b-input-group-append>
        </b-input-group>
      </template>
      <template v-else>
        <b-input-group class="mt-2 w-300px">
          <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
          <b-form-input maxlength="6" class="form-control" v-model="form.otpCode" placeholder="OTP 코드 입력(6자리)" @keypress.enter="registOtp"/>
          <b-input-group-append><b-button variant="success" @click="verify">인증 테스트</b-button></b-input-group-append>
        </b-input-group>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 130px 1fr;
  margin: 10px 0;
}
.grid .td {
  margin-bottom: 5px;
}
</style>

<script>

export default {
  name: 'AuthModal',
  model: {prop: 'value', event: 'change'},
  props: ['value'],
  data() {
    return {
      form: {
        pw: '',
        new_pw: '',
        new_pw_again: '',
        qr: '',
        otpSecret: '',
        otpCode: '',
      },
      item: {},
      item_org: {},
      emailLimitSecond: 180,
      smsLimitSecond: 180,
      busy: {
        me: false,
        save: false,
        pw: false,
        sendEmail: false,
        waitEmail: false,
        checkEmail: false,
        sendSMS: false,
        waitSMS: false,
        checkSMS: false,
        qr: false,
        verify: false,
      },
      handle: {
        waitEmail: null,
        expireEmail: null,
        waitSMS: null,
        expireSMS: null,
      },
      readonly: {
        email: false,
        mobile: false,
      }
    }
  },
  async created() {
    this.getMe();
    if (!this.$S.user.otp_reg_dt) {
      this.generateQrCode();
    }
  },
  computed: {
    modal: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  methods: {
    async getMe() {
      // console.log('getMe');
      this.busy.me = true;
      const j = await this.$api.getJson('/user/me');
      this.busy.me = false;
      if (!j) return;
      this.item_org = j.user;
      this.item = this.$utils.clone(j.user);
    },
    async sendAuthEmail() {
      if (!this.item.email.match(/^[a-zA-Z0-9+\-_.]+@([-a-zA-Z0-9]+\.)+[-a-zA-Z0-9]+$/)) {
        this.$utils.alert('Email 을 형식에 맞게 입력해주세요');
        return;
      }
      clearTimeout(this.handle.waitEmail);
      clearInterval(this.handle.expireEmail);
      this.busy.sendEmail = true;
      const j = await this.$api.postJson('/user/sendAuthEmail', {email: this.item.email});
      this.busy.sendEmail = false;
      if (j) {
        this.busy.waitEmail = true;
        this.emailLimitSecond = 180;
        // 다량발송 방지
        this.handle.waitEmail = setTimeout(() => {
          this.busy.waitEmail = false;
          this.$forceUpdate();
        }, 15000);
        // 입력한도시간 표시
        this.handle.expireEmail = setInterval(() => {
          if (--this.emailLimitSecond <= 0) clearInterval(this.handle.expireEmail);
        }, 1000);
        this.item.email_sended = this.item.email;
        this.$utils.alert('인증메일이 발송되었습니다. 확인 후 입력해주세요');
        this.$forceUpdate();
      }
    },
    async checkAuthEmail() {
      if (!this.item.emailAuthCode) return alert('인증번호를 입력해주세요');
      const email = this.item.email;
      const j = await this.$api.postJson('/user/checkAuthEmail', {email, authCode: this.item.emailAuthCode});
      if (j) {
        this.item.email_auth = email;
        this.item.email_txid = j.txid;
        this.readonly.email = true;
        clearInterval(this.handle.expireEmail);
        this.$utils.alert('Email 이 인증되었습니다');

        // mypage 에서는 인증과 동시에 저장한다.
        this.busy.save = true;
        const res = await this.$api.postJson('/user/updateMe', {item: {email, email_txid: j.txid}});
        this.busy.save = false;
        if (res) {
          this.$S.user.email = email;
          localStorage.setItem('user', JSON.stringify(this.$S.user));
          this.$S.user.email_checked_dt = this.$utils.kstDT();
        }
      }
    },
    async sendAuthSMS() {
      if (!this.item.mobile || this.item.mobile.match(/\D/)) {
        this.$utils.alert('휴대폰 번호는 숫자로만 입력해주세요');
        return;
      }
      if (!this.item.mobile.match(/^(820|82|0)[157]\d[2-9]\d{6,8}/)) {
        this.$utils.alert('휴대폰 번호는 문자를 받을 수 있는 번호로 입력해주세요');
        return;
      }
      clearTimeout(this.handle.waitSMS);
      clearInterval(this.handle.expireSMS);
      this.busy.sendSMS = true;
      const j = await this.$api.postJson('/user/sendAuthSMS', {mobile: this.item.mobile});
      this.busy.sendSMS = false;
      if (j) {
        this.busy.waitSMS = true;
        this.smsLimitSecond = 180;
        // 다량발송 방지
        this.handle.waitSMS = setTimeout(() => {
          this.busy.waitSMS = false;
          this.$forceUpdate();
        }, 15000);
        // 입력한도시간 표시
        this.handle.expireSMS = setInterval(() => {
          if (--this.smsLimitSecond <= 0) clearInterval(this.handle.expireSMS);
        }, 1000);
        this.item.mobile_sended = this.item.mobile;
        this.$utils.alert('인증문자가 발송되었습니다. 확인 후 입력해주세요');
        this.$forceUpdate();
      }
    },
    async checkAuthSMS() {
      if (!this.item.smsAuthCode) return alert('인증번호를 입력해주세요');
      const mobile = this.item.mobile;
      const j = await this.$api.postJson('/user/checkAuthSMS', {mobile, authCode: this.item.smsAuthCode});
      if (j) {
        this.item.mobile_auth = mobile;
        this.item.mobile_txid = j.txid;
        this.readonly.mobile = true;
        clearInterval(this.handle.expireSMS);
        this.$utils.alert('휴대폰 번호가 인증되었습니다');

        // mypage 에서는 인증과 동시에 저장한다.
        this.busy.save = true;
        const res = await this.$api.postJson('/user/updateMe', {item: {mobile, mobile_txid: j.txid}});
        this.busy.save = false;
        if (res) {
          this.$S.user.mobile = mobile;
          localStorage.setItem('user', JSON.stringify(this.$S.user));
          this.$S.user.mobile_checked_dt = this.$utils.kstDT();
        }
      }
    },
    async changePW() {
      if (this.form.pw.length < 8) return this.$utils.alert('기존 비밀번호를 8자 이상으로 입력해주세요');
      // if (this.form.new_pw.length < 8) return this.$utils.alert('새 비밀번호를 8자 이상으로 입력해주세요');
      if (!this.checkPW(this.form.new_pw)) {
        return this.$utils.alert('새 비밀번호는 알파벳 대문자, 소문자, 특수문자, 숫자 중 두가지 종류 이상 8자리 이상의 길이 혹은 10자리 이상의 길이로 입력해주세요');
      }
      if (this.form.new_pw !== this.form.new_pw_again) return this.$utils.alert(`새 비밀번호는 새 비밀번호 확인과 동일해야 합니다`);
      if (this.form.new_pw === this.form.pw) return this.$utils.alert(`기존 비밀번호와 동일한 새 비밀번호는 사용하실 수 없습니다.`);

      this.busy.pw = true;
      const j = await this.$api.postJson('/user/changePW', {pw: this.$utils.sha256(this.form.pw), new_pw: this.$utils.sha256(this.form.new_pw)});
      this.busy.pw = false;
      if (j) {
        await this.$utils.alert('비밀번호가 변경되었습니다');
        this.form.pw = this.form.new_pw = this.form.new_pw_again = '';
        this.$S.user.pw_mod_dt = this.$utils.kstDT();
      }
    },
    /**
     * 새롭게 나온 kisa 패스워드 선택 및 이용 안내서(2019.06) 에 따른 체크
     * https://skyksit.tistory.com/entry/%EB%B9%84%EB%B0%80%EB%B2%88%ED%98%B8-%EC%B2%B4%EA%B3%84-%EB%B3%80%EA%B2%BD-%EB%90%A8-11%EB%85%84-%EB%A7%8C%EC%97%90
     * 알파벳 대문자, 소문자, 특수문자, 숫자 중 두가지 종류 이상 8자리 이상
     * 한 가지 종류로 10자 이상
     * 6개월마다 변경 필요없음
     *
     * @param {string} pw
     * @return {boolean}
     */
    checkPW(pw) {
      // 문자그룹별 카운트
      const lower = pw.match(/[a-z]/);
      const upper = pw.match(/[A-Z]/);
      const num = pw.match(/[0-9]/);
      const etc = pw.match(/[^0-9a-zA-Z]/);
      const count = [lower, upper, num, etc].filter(e => e).length;
      return count >= 2 && pw.length >= 8 || pw.length >= 10;
    },
    async generateQrCode() {
      this.busy.qr = true;
      const j = await this.$api.postJson('/user/otp/generate', {});
      this.busy.qr = false;
      if (j) {
        this.form.qr = j.data.qrData;
        this.form.otpSecret = j.data.secret;
      }
    },
    async registOtp() {
      if (this.form.otpCode.length !== 6) return alert('OTP 코드를 6자리로 입력해주세요');
      this.busy.verify = true;
      const j = await this.$api.postJson('/user/otp/registOtp', {code: this.form.otpCode, secret: this.form.otpSecret});
      this.busy.verify = false;
      if (j) {
        this.$alertTop('성공적으로 인증되었습니다.');
        this.form.otpSecret = '';
        this.form.otpCode = '';
        this.$S.user.otp_reg_dt = j.otp_reg_dt;
      }
    },
    async verify() {
      if (this.form.otpCode.length !== 6) return alert('OTP 코드를 6자리로 입력해주세요');
      this.busy.verify = true;
      const j = await this.$api.postJson('/user/otp/verify', {code: this.form.otpCode});
      this.busy.verify = false;
      if (j) {
        this.$alertTop('성공적으로 인증되었습니다.');
        this.form.otpCode = '';
      }
    }
  }
}
</script>

