<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        :src="$S.user.img"
        class="img-avatar"
        :alt="$S.user.name" />
    </template>
    <template slot="dropdown">
      <b-dropdown-item to="/mypage"><i class="fa fa-user" /> 마이페이지</b-dropdown-item>
      <b-dropdown-item v-if="PRIVATE && $S.user.roles && ~$S.user.roles.indexOf('USER_R')" to="/users"><i class="fa fa-users" /> 사용자관리</b-dropdown-item>
      <b-dropdown-item v-if="PRIVATE && $S.user.roles && ~$S.user.roles.indexOf('USER_CS_R')" to="/cs_users"><i class="fa fa-users" /> 위탁사관리</b-dropdown-item>
      <b-dropdown-item v-if="PRIVATE && $S.user.roles && ~$S.user.roles.indexOf('AUDIT')" to="/audit_logs"><i class="fa fa-legal" /> 감사 로그</b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="logout"><i class="fa fa-lock" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import store from '@/store';
const {commit: C} = store;

export default {
  name: 'DefaultHeaderDropdown',
  components: {
    AppHeaderDropdown
  },
  data() {
    return {
    }
  },
  methods: {
    async logout() {
      await this.$api.postJson('/user/logout', {});
      C('logout');
      this.$router.push('/pages/login');
    }
  }
}
</script>

