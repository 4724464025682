<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile/>
      <b-link class="navbar-brand" to="/">
        <!--        <img class="navbar-brand-full" src="img/brand/logo.svg" width="89" height="25" alt="CoreUI Logo">-->
        <!--        <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo">-->
        Balaan Hub
        <small v-if="PRIVATE"><b-badge variant="danger" style="margin-bottom:18px;margin-left:4px;">Private</b-badge></small>
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg"/>
      <b-navbar-nav class="d-md-down-none" v-if="IS_SERVE">
        <b-button size="sm" :variant="port === 3030 ? 'primary' : 'light'" @click="changePort(3030)">Prod</b-button>
        <b-button size="sm" :variant="port === 3230 ? 'warning' : 'light'" @click="changePort(3230)">Dev</b-button>
      </b-navbar-nav>
      <b-navbar-nav class="ml-2 d-md-down-none" v-if="project">
        <h3>
          <b-badge :variant="ENV_VARIANT">{{ENV.toUpperCase()}}</b-badge>
        </h3>
        <h4 class="ml-2">
          Project: <span v-html="project"></span>
          <span v-if="branch"> (branch: {{branch}})</span>
        </h4>
      </b-navbar-nav>
      <ul class="navbar-nav d-lg-none">
        <DefaultHeaderDropdown/>
      </ul>
      <b-navbar-nav class="d-md-down-none ml-auto">
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdown/>
        </b-nav-item>
      </b-navbar-nav>
      <!--<AsideToggler class="d-none d-lg-block" />-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="navItems"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <template v-if="type === 'full'">
          <router-view></router-view>
        </template>
        <template v-else-if="type === 'white'">
          <div class="bg-white" style="height: 100%">
            <Breadcrumb :list="list" class="mb-2"/>
            <div class="px-2">
              <router-view></router-view>
            </div>
          </div>
        </template>
        <template v-else>
          <Breadcrumb :list="list" class="mb-2"/>
          <div class="px-2">
            <router-view></router-view>
          </div>
        </template>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://hub.balaan.io">BalaanHub</a>
        <span class="ml-1">&copy; {{$utils.kstD().slice(0, 4)}} BALAAN.</span>
      </div>
      <!--<div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://coreui.io">CoreUI for Vue</a>
      </div>-->
    </TheFooter>
    <modal-base></modal-base>
    <b-alert
      v-model="$S.alertTop.show"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      :variant="$S.alertTop.variants || 'success'"
      dismissible
    >
      {{ $S.alertTop.text }}
    </b-alert>
    <auth-modal v-if="modal.auth" v-model="modal.auth"></auth-modal>

    <!-- div 의 display 상태를 이용하여 현재 화면의 breakpoint 를 알아내는 방법 -->
    <div v-b-visible="e => $S.breakpoint.isLteXs = e" class="position-fixed d-block d-sm-none" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isLteSm = e" class="position-fixed d-block d-md-none" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isLteMd = e" class="position-fixed d-block d-lg-none" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isLteLg = e" class="position-fixed d-block d-xl-none" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isGteSm = e" class="position-fixed d-none d-sm-block" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isGteMd = e" class="position-fixed d-none d-md-block" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isGteLg = e" class="position-fixed d-none d-lg-block" style="z-index: 10000; height: 0;"></div>
    <div v-b-visible="e => $S.breakpoint.isGteXl = e" class="position-fixed d-none d-xl-block" style="z-index: 10000; height: 0;"></div>
  </div>
</template>

<style>
.sidebar .nav-link {
  padding: .35rem 1rem;
}
</style>

<script>
import nav from '@/_nav'
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
import AuthModal from './AuthModal'

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdown,
    AuthModal,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data() {
    return {
      modal: {
        auth: false,
      },
      project: '',
      branch: '',
      // navItems: nav.items.filter(e=>!e.role || S.user.roles && (~S.user.roles.indexOf(e.role) || this.$utils.typeOf(e.role) === 'array' && e.role.some(e=>~S.user.roles.indexOf(e))))
    }
  },
  computed: {
    name() {
      return this.$route.name
    },
    type() {
      const lastMenu = this.$route.matched.filter((route) => route.name || route.meta.label).slice(-1)[0];
      return lastMenu && lastMenu.meta && lastMenu.meta.type;
    },
    list() {
      return this.$route.matched.filter((route) => route.name || route.meta.label)
    },
    navItems() {
      return nav.items.filter(e =>
        !(!this.PRIVATE && e.private) // PRIVATE 모드가 아닐 때는 private 메뉴는 숨김
        &&
        (!e.role ||
        this.$S.user.roles && (
          ~this.$S.user.roles.indexOf(e.role) ||
          this.$utils.typeOf(e.role) === 'array' && e.role.some(e => ~this.$S.user.roles.indexOf(e))
        ))
      );
    },
    alertTopShow() {
      return this.$S.alertTop.show;
    }
  },
  async created() {
    // 04-26 종료
    // this.checkNeedAuth();
    if (this.ENV !== 'prod' || this.IS_LOCAL) {
      const j = await this.$api.getJson('/project');
      if (j) {
        this.project = j.project;
        this.branch = j.branch;
      }
    }
  },
  methods: {
    changePort(port) {
      localStorage.setItem('port', port);
      location.reload();
    },
    async checkNeedAuth() {
      const j = await this.$api.getJson('/user/me');
      if (j) {
        const user = j.user;
        if (!user.mobile_checked_dt || !user.email_checked_dt || !user.pw_mod_dt) {
          const t = localStorage.getItem('authModalTS');
          if (!t || t < +new Date - 3600000 * 4) { // 4 시간 초과
            localStorage.setItem('authModalTS', +new Date);
            this.modal.auth = true;
          }
        }
      }
    }
  }
}
</script>
